import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ children, to, openInNewTab, className }) => {
  if (!to) return <>{children}</>;

  const isEmailLink = to.includes('mailto:');
  const isTelLink = to.includes('tel:');

  if (
    !openInNewTab &&
    !isEmailLink &&
    !isTelLink &&
    to.indexOf('workgrid.') > -1
  ) {
    const urlCleanup = to.replace(
      /(https?:\/\/www.workgrid\.(io|com))?/gi,
      ''
    );
    return (
      <GatsbyLink to={urlCleanup} className={className}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      href={to}
      target={`_${openInNewTab ? 'blank' : 'self'}`}
      rel="noopener noreferrer"
      className={className}
      aria-label={to}
    >
      {children}
    </a>
  );
};

export default Link;
