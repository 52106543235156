export const createVideoLinks = (tiles) => {
  let videoLinks = []
  tiles?.map((tile, index) => videoLinks.push({
    id: "video-"+index, 
    link: tile?.videoLink,
    reference: {
      kicker: tile?.kicker,
      title: tile?.heading,
      caption: tile?.videoCaption,
      ctaButton:  {
        link: `/${tile?.ctaLink}`,
        label: tile?.ctaLabel,
        variant: tile?.ctaVariant
      },
    },
    thumbnail: tile?.icon
  }))
  return videoLinks
}

export const createReference = (tile) => {
  const {
    heading, 
    kicker,
    videoCaption,
    ctaLink,
    ctaLabel,
    ctaVariant,
  } = tile

  return {
    kicker: kicker,
    title: heading,
    caption: videoCaption,
    ctaButton: {
      link: `${ctaLink[0] !== '/' && ctaLink.slice(0,4) !== 'http' ? '/' : ''}${ctaLink}`,
      label: ctaLabel,
      variant: ctaVariant
    }
  }
}